exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (!location.state?.preventScroll) {
    const currentPosition = getSavedScrollPosition(location);
    window.scrollTo(...(currentPosition || [0, 0]));
    return true;
  }

  return false;
};

exports.onRouteUpdate = () => {
  window.analytics?.page(document.title);

  setTimeout(() => {
    window.dispatchEvent(
      new CustomEvent('backdrop-route-update', {
        url: window.location.toString(),
      })
    );
    window.onPageChange?.({
      url: window.location.toString(),
    });
    window.dataLayer?.push({
      event: 'gatsby-route-change',
      url: window.location.toString(),
    });
  }, 50);
};

exports.onInitialClientRender = () => {
  setTimeout(() => {
    window.dispatchEvent(
      new CustomEvent('backdrop-route-update', {
        url: window.location.toString(),
      })
    );
    window.onPageChange?.({
      url: window.location.toString(),
    });
    window.dataLayer?.push({
      event: 'gatsby-route-change',
      url: window.location.toString(),
    });
  }, 50);
};
