import { createSelector } from 'reselect';

const selectCheckoutId = createSelector(
  (state) => state.ui,
  (ui) => {
    return ui.activeHeaderSubMenu;
  }
);

export default selectCheckoutId;
