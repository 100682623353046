import { PRODUCT_VIEWED, PRODUCT_ADDED } from './segmentActions';

export const track = async (action, properties, options, callback) => {
  if (window.hj) {
    window.hj('event', action.toLowerCase().replace(/ /g, '_'));
  }
  if (window.analytics) {
    window.analytics.track(action, properties, options, callback);
  } else {
    window.analyticsQueue
      ? window.analyticsQueue.push({ action, properties, options, callback })
      : (window.analyticsQueue = [{ action, properties, options, callback }]);
  }

  if (window.attentive) {
    if (action === PRODUCT_VIEWED) {
      window.attentive.analytics.productView({
        items: [
          {
            productId: properties.product_id,
            name: properties.name,
            productImage: properties.image_url,
            category: 'food',
          },
        ],
      });
    }
    if (action === PRODUCT_ADDED) {
      window.attentive.analytics.addToCart({
        items: [
          {
            productId: properties.product_id,
            productVariantId: properties.sku,
            name: properties.variant || properties.name,
            price: {
              value: properties.price,
              currency: 'USD',
            },
            quantity: properties.quantity,
          },
        ],
      });
    }
  }
};

export const page = (category, name, properties, options, callback) =>
  window.analytics
    ? window.analytics.track(category, name, properties, options, callback)
    : undefined;

export const ready = (category, name, properties, options, callback) => {
  if (window.analytics) {
    window.analytics ? window.analytics.ready(callback) : undefined;
  } else {
    window.analyticsReadyFns
      ? window.analyticsReadyFns.push(callback)
      : (window.analyticsReadyFns = [callback]);
  }
};

export const identifyByEmail = (id) =>
  window.analytics ? window.analytics.identify(id, { email: id }) : undefined;

export const identify = (id, traits, options, callback) =>
  window.analytics
    ? window.analytics.identify(id, traits, options, callback)
    : undefined;

export const reset = () =>
  window.analytics ? window.analytics.reset() : undefined;

export const group = (groupId, traits, options) =>
  window.analytics
    ? window.analytics.group(groupId, traits, options)
    : undefined;

export const getAnonymousId = () =>
  window.analytics
    ? window.analytics.user && window.analytics.user().anonymousId()
    : undefined;
