import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeToast, popToast } from '../state/ui/uiSlice';
import { Paragraph } from 'grommet';
import SmartLink from './SmartLink';

export function useCookieAcknowledgement() {
  const [isCookieAccepted, setIsCookieAccepted] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const cookieAccepted = localStorage.getItem('cookieAccepted');
    setIsCookieAccepted(!!cookieAccepted);
  }, []);

  const openCookieAcknowledgement = () => {
    dispatch(
      popToast({
        id: 'cookie-acknowledgement',
        title: 'Cookie Policy',
        onClose: () => {
          acceptCookie();
        },
        body: (
          <Paragraph margin="none" size="small">
            This website uses cookies to enhance the user experience.{' '}
            <SmartLink
              onClick={() => {
                acceptCookie();
              }}
              to="/pages/privacy-policy"
            >
              Learn more
            </SmartLink>
            .
          </Paragraph>
        ),
        type: 'info',
      })
    );
  };

  const acceptCookie = () => {
    localStorage.setItem('cookieAccepted', 'true');
    setIsCookieAccepted(true);
    dispatch(closeToast({ id: 'cookie-acknowledgement' }));
  };

  return {
    openCookieAcknowledgement,
    isCookieAccepted,
    acceptCookie,
  };
}
