import { useEffect } from 'react';
import { useCookieAcknowledgement } from './useCookieAcknowledgement';

export function CookieAcknowledgement() {
  const { isCookieAccepted, openCookieAcknowledgement } =
    useCookieAcknowledgement();

  useEffect(() => {
    if (!isCookieAccepted) {
      openCookieAcknowledgement();
    }
  }, [isCookieAccepted]);
}
